// client/src/pages/PrivacyPolicy.js
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import logo from "../assets/images/logo.png";

const PrivacyPolicy = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("/Scanprofit_Privacy_Policy.md")
      .then((response) => response.text())
      .then((text) => setContent(text));
  }, []);

  return (
    <div className="p-5 flex flex-col">
      <img src={logo} alt="logo" className="text-center w-28 h-auto mb-5" />
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
};

export default PrivacyPolicy;
