import React from "react";

const StorefrontSkeleton = () => {
  return (
    <div className="animate-pulse">
      <div className="flex justify-between">
        <div className="flex flex-col p-3 rounded-xl border-2 border-border card gap-y-4 w-[32%] h-fit">
          <h1 className="h-4 w-40 rounded-lg bg-primary-bg/70"></h1>
          <div className="bg-secondary-bg p-3 rounded-xl flex flex-col gap-y-3">
            {[1, 2, 3, 4, 5].map((e, i) => {
              return (
                <div
                  className="w-full flex items-center justify-between"
                  key={e}
                >
                  <p className="bg-primary-bg/40 h-3 rounded-lg w-24"></p>
                  <p className="bg-primary-bg/40 h-3 rounded-lg w-20"></p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col p-3 rounded-xl border-2 border-border card gap-y-4 w-[32%] h-fit">
          <h1 className="h-4 w-40 rounded-lg bg-primary-bg/70"></h1>

          <div className="p-3 w-full flex flex-col gap-y-2 justify-center items-center">
            {[1, 2, 3, 4, 5].map((e, i) => {
              return (
                <div
                  className="flex items-center w-full bg-primary-bg/40 rounded-full p-2 h-5"
                  key={i}
                ></div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col p-3 rounded-xl border-2 border-border card gap-y-4 w-[32%]">
          <h1 className="h-4 w-40 rounded-lg bg-primary-bg/70"></h1>

          <div className="p-3 w-full flex flex-col gap-y-2 justify-center items-center">
            {[1, 2, 3, 4, 5].map((e, i) => {
              return (
                <div
                  className="flex items-center w-full bg-primary-bg/40 rounded-full p-2 h-5"
                  key={i}
                ></div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorefrontSkeleton;
