import React from "react";
import sellerNotFound from "../../assets/images/sellerNotFound.svg";

const SellerNotFound = () => {
  return (
    <div className="w-full h-[75vh] flex items-center justify-center flex-col gap-y-2">
      <img src={sellerNotFound} className="w-48" />
      <p className="text-gray-200 text-lg">Seller Not found!</p>
    </div>
  );
};

export default SellerNotFound;
