import React, { useState } from "react";
import { connect } from "react-redux";
import Dashboard from "./Dashboard";
import Sidebar from "../components/Sidebar";
import { useLocation } from "react-router-dom";
import Storefront from "./Storefront";
import NotFound from "./NotFound";

export const Index = (props) => {
  const [sidebarClosingStatus, setSidebarClosingStatus] = useState(true);
  const location = useLocation();
  return (
    <div className="flex">
      <div
        id="sidebar"
        className="invisible sm:visible transition-all duration-150 ease-in-out"
      >
        <Sidebar
          setSidebarClosingStatus={setSidebarClosingStatus}
          sidebarClosingStatus={sidebarClosingStatus}
        />
      </div>
      <div
        className={`w-full  ${
          sidebarClosingStatus ? `sm:ml-[240px]` : `sm:ml-[80px]`
        }`}
      >
        {location.pathname === "/dashboard" ? (
          <Dashboard setSidebarClosingStatus={setSidebarClosingStatus} />
        ) : location.pathname === "/storefront-stalking" ? (
          <Storefront />
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
