import React from "react";
import TooltipSimple from "./TooltipSimple";
import Rating from "@mui/material/Rating";
import { IoMdStar } from "react-icons/io";
import formatNumber from "./helpers/formatNumber";

const Offers = ({ offersData }) => {
  // const offers = [
  //   {
  //     id: 4,
  //     price: "29.99",
  //     sellerId: "A32LBE1E3WV7OQ",
  //     isFBA: true,
  //     isPrime: true,
  //     isAmazon: false,
  //     stock: 5,
  //   },
  //   {
  //     id: 4,
  //     price: "29.99",
  //     sellerId: "A32LBE1E3WV7OQ",
  //     isFBA: false,
  //     isPrime: true,
  //     isAmazon: false,
  //     stock: 5,
  //   },
  //   {
  //     id: 4,
  //     price: "29.99",
  //     sellerId: "A32LBE1E3WV7OQ",
  //     isFBA: false,
  //     isPrime: true,
  //     isAmazon: true,
  //     stock: 5,
  //   },
  //   {
  //     id: 4,
  //     price: "29.99",
  //     sellerId: "A32LBE1E3WV7OQ",
  //     isFBA: true,
  //     isPrime: true,
  //     isAmazon: false,
  //     stock: 5,
  //   },
  //   {
  //     id: 4,
  //     price: "29.99",
  //     sellerId: "A32LBE1E3WV7OQ",
  //     isFBA: true,
  //     isPrime: true,
  //     isAmazon: false,
  //     stock: 5,
  //   },
  // ];
  const getRating = (percentage) => {
    // Ensure percentage is between 0 and 100
    if (percentage < 0) percentage = 0;
    if (percentage > 100) percentage = 100;
    const stars = (percentage / 20).toFixed(1);

    return parseFloat(stars);
  };
  return (
    <div className="bg-secondary-bg flex items-center justify-center rounded-xl text-white flex-col w-full p-2">
      <div className="flex items-center w-full">
        <p className="w-[24%] text-center"> </p>
        <p className="w-[24%] text-center">Seller</p>
        <p className="w-[24%] text-center">Price</p>
        <p className="w-[24%] text-center">Stock</p>
      </div>
      <div className="w-full py-2 flex flex-col gap-y-1">
        {offersData?.offers
          ?.slice(0, 5)
          .map(
            (
              {
                price,
                isAmazon,
                isFBA,
                stock,
                sellerId,
                sellerName,
                sellerRating,
                sellerRatingCount,
              },
              i
            ) => {
              return (
                <div
                  className="bg-[#153E27] px-3 py-2 rounded-xl flex items-center gap-x-2 w-full"
                  key={i}
                >
                  <p className="w-[24%] text-center">{i + 1}</p>
                  <div className="group relative cursor-pointer w-[24%] flex items-center justify-center">
                    {isAmazon ? (
                      <a
                        className="bg-[#644513] border border-[#d5a109] p-1 rounded-lg text-center w-full"
                        href={
                          process.env.REACT_APP_CLIENT_BASE_URL +
                          "/storefront-stalking?q=" +
                          sellerId
                        }
                        target="_blank"
                      >
                        AMZ
                      </a>
                    ) : isFBA ? (
                      <a
                        className="bg-[#592810] border border-[#d5a109] p-1 rounded-lg text-center w-full"
                        href={
                          process.env.REACT_APP_CLIENT_BASE_URL +
                          "/storefront-stalking?q=" +
                          sellerId
                        }
                        target="_blank"
                      >
                        FBA
                      </a>
                    ) : (
                      <a
                        className="bg-blue-900/40 border border-[#3b82f6] p-1 rounded-lg text-center w-full"
                        href={
                          process.env.REACT_APP_CLIENT_BASE_URL +
                          "/storefront-stalking?q=" +
                          sellerId
                        }
                        target="_blank"
                      >
                        FBM
                      </a>
                    )}
                    <TooltipSimple
                      width={"auto"}
                      CustomJsx={() => (
                        <div className="flex items-center gap-y-1 flex-col py-1">
                          <p className="text-medium">{sellerName}</p>
                          {!isAmazon && (
                            <div className="flex gap-x-1 items-center px-2">
                              <Rating
                                name="read-only"
                                value={Number(getRating(sellerRating))}
                                precision={0.1}
                                readOnly
                                style={{
                                  fontSize: "13px",
                                  color: "#76F99B",
                                }}
                                emptyIcon={
                                  <IoMdStar className="text-[#76F99B80]" />
                                }
                              />
                              <span>{`(${formatNumber(
                                sellerRatingCount
                              )})`}</span>
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <p className="w-[24%] text-center">{"$" + price}</p>
                  <p className="w-[24%] text-center">{stock}</p>
                </div>
              );
            }
          )}
      </div>
    </div>
  );
};

export default Offers;
