import { Toaster } from "sonner";
import PrivateRoute from "./components/PrivateRoute";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Authentication from "./pages/Authentication/Authentication.js";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import ResetPassword from "./pages/Authentication/ResetPassword";
import Index from "./pages/Index";
import PaymentTables from "./pages/PaymentTable/PaymentTables.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";

function App() {
  return (
    <>
      <Toaster position="top-right" richColors closeButton />
      <Routes>
        <Route path="/" element={<Navigate to="/signup" />} />
        <Route path="/signup" element={<Authentication />} />
        <Route path="/signin" element={<Authentication />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/payment-options" element={<PaymentTables />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route element={<PrivateRoute />}>
          <Route path="/*" element={<Index />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
