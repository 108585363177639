import React from "react";

const ProductLoadingSkeleton = () => {
  return (
    <div className="flex flex-col gap-y-3 animate-pulse">
      {[1, 2, 3, 4, 5].map(() => {
        return (
          <div className="flex items-center border-2 border-border rounded-xl p-4 justify-between">
            <div className="w-[38%]">
              <div className="flex flex-col gap-y-4 bg-secondary-bg rounded-xl">
                <div className="flex gap-x-2 p-3">
                  <div className="w-32 h-32 rounded-xl bg-primary-bg/60"></div>
                  <div className="w-1/2 flex flex-col gap-y-3">
                    <p className="font-medium text-primary h-4 bg-primary-bg/40 rounded-lg w-3/4"></p>
                    <p className="font-medium text-primary h-4 bg-primary-bg/40 rounded-lg w-1/3"></p>
                    <p className="font-medium text-primary h-4 bg-primary-bg/40 rounded-lg w-[60%]"></p>
                  </div>
                  <div className="flex flex-col p-3 rounded-xl bg-[#23554380] gap-y-2 h-fit ml-auto">
                    <div className="p-3 rounded-lg bg-primary cursor-pointer"></div>
                    <div className="p-3 rounded-lg bg-primary cursor-pointer"></div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div className="w-[30%]">
              <div className="bg-secondary-bg flex items-center justify-center rounded-xl text-white flex-col w-full p-2">
                <div className="w-full py-2 flex flex-col gap-y-3">
                  {[1, 2, 3, 4].map((e, i) => {
                    return (
                      <div
                        className="bg-[#153E27] p-3 rounded-lg flex items-center w-full"
                        key={i}
                      ></div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="bg-secondary-bg rounded-xl items-center flex justify-center w-[30%] h-40"></div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductLoadingSkeleton;
