import React from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(...registerables);

const OfferChart = ({ data }) => {
  const epochToLocalTime = (epochTime) => {
    const localDate = new Date(epochTime * 1000);
    return localDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
    });
  };
  const extractLabels = (data) =>
    data?.map((d) => epochToLocalTime(d.time)) || [];
  const labels = extractLabels(data);

  const valueFormatter = (num) => {
    if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(1) + "B";
    if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + "M";
    if (num >= 1_000) return (num / 1_000).toFixed(1) + "k";
    return num.toFixed(0);
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Offers",
        data: data?.map((item) => item.value),
        borderColor: "#a78bfa",
        borderWidth: 1,
        stepped: true,
        pointRadius: 0,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || "";
            const value = context.raw || 0;
            return `${label}: ${valueFormatter(value)}`;
          },
        },
      },
      legend: {
        display: false, // Disable legend to remove labels at the top
      },
    },
    scales: {
      x: {
        type: "category",
        ticks: {
          color: "#E5E7EB",
          font: {
            size: 10,
          },
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          color: "#d1d5db",
          width: 1,
        },
      },
      y: {
        ticks: {
          callback: valueFormatter,
          color: "#E5E7EB",
          maxTicksLimit: 5,
        },
        grid: {
          display: false,
        },
        border: {
          color: "#d1d5db",
          width: 1,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div
      className="bg-secondary-bg flex flex-col rounded-lg shadow-sm w-full p-1 mt-3 "
      style={{
        minWidth: "100%",
        height: "170px",
      }}
    >
      {data?.length > 0 ? (
        <Line
          data={chartData}
          options={chartOptions}
          style={
            {
              // minWidth: '100%'
            }
          }
        />
      ) : (
        <div className="flex items-center w-full h-full opacity-90 justify-center text-gray-100">
          No data
        </div>
      )}
    </div>
  );
};

export default OfferChart;
