import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getCredits,
  handleAddCredits,
  handlePortalSession,
} from "../redux/actions/payment";
import { toast } from "sonner";

export const Dashboard = (props) => {
  const { userCredits } = useSelector((state) => state.stripe);
  const dispatch = useDispatch();
  const buttonData = [
    {
      credits: 200,
      amount: 10,
      priceId: process.env.REACT_APP_ADD_200_CREDITS,
    },
    {
      credits: 500,
      amount: 25,
      priceId: process.env.REACT_APP_ADD_500_CREDITS,
    },
    {
      credits: 750,
      amount: 50,
      priceId: process.env.REACT_APP_ADD_750_CREDITS,
    },
  ];
  const handleAddButtonClick = async (priceId) => {
    handleAddCredits(priceId);
  };
  useEffect(() => {
    dispatch(getCredits());
    const queryParameters = new URLSearchParams(window.location.search);
    const payment_status = queryParameters.get("payment_status");
    const session_id = queryParameters.get("session_id");
    if (payment_status && session_id) {
      if (payment_status === "success") {
        toast.success("Payment successful");
      } else if (payment_status === "failed") {
        toast.error("Payment failed!");
      }
      if (session_id) {
        queryParameters.delete("session_id");
      }
      queryParameters.delete("payment_status");
      // remove query parameters from url
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);
  return (
    <div className="bg-primary-bg min-h-screen">
      <p className="title border-b-2 border-b-border w-full p-5 text-gray-100">
        Dashboard
      </p>
      <div className="px-8 py-4">
        <div className="flex items-center gap-x-5 pt-4 w-full">
          <div className="flex flex-col gap-y-2 p-6 rounded-lg border-2 border-border shadow w-1/4 card">
            <p className="text-primary">Your Balance</p>
            <p className="text-2xl font-medium text-gray-300">
              {userCredits?.credit_balance}
            </p>
          </div>
          <div className="flex flex-col gap-y-2 p-6 rounded-lg border-2 border-border shadow w-1/4 card">
            <p className="text-primary">Total Credits Purchased</p>
            <p className="text-2xl font-medium text-gray-300">
              {userCredits?.credit_total}
            </p>
          </div>
          <div className="flex flex-col gap-y-1 p-4 rounded-lg border-2 border-border shadow w-1/4 card">
            <p className="text-primary">Your Plan</p>
            <p className="text-2xl font-medium text-gray-300">
              {userCredits?.plan ? userCredits?.plan : "-"}
            </p>
            <p className="text-gray-300 ml-auto text-sm">
              Ending at{" "}
              {new Date(userCredits?.subscriptionEnd).toLocaleDateString(
                "en-GB"
              )}
            </p>
          </div>
        </div>
        {/* <div className="flex gap-x-3 items-center py-5">
          {buttonData?.map(({ credits, amount, priceId }, i) => {
            return (
              <button
                className="px-6 py-3 rounded-lg border-2 border-border text-primary shadow font-medium hover:border-green-700 transition-all duration-150 ease-in-out card"
                key={i}
                onClick={() => handleAddButtonClick(priceId)}
              >
                Buy {credits} credits for ${amount}
              </button>
            );
          })}
        </div> */}
        <div className="flex items-center gap-x-4 py-5">
          <button
            className="px-6 py-3 rounded-lg border-2 border-border text-primary shadow font-medium hover:border-green-700 transition-all duration-150 ease-in-out card"
            onClick={handlePortalSession}
          >
            Change your plan
          </button>
          <div className="flex items-center gap-x-3 px-6 py-2.5 border-2 border-border text-primary shadow transition-all duration-150 ease-in-out w-fit rounded-lg font-medium card">
            <p> Subscription status: </p>
            <p
              className={`${
                userCredits?.subscriptionActive
                  ? "bg-primary text-black"
                  : "bg-red-600 text-gray-100"
              } rounded-lg px-3 py-0.5`}
            >
              {userCredits?.subscriptionActive ? "Active" : "Inactive"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
