const initState = {
  sellerInfoLoading: false,
  data: null,
  sellerProductLoading: false,
  sellerProductData: null,
  moreProductsLoading: false,
  chartUrls: [],
};
export default (state = initState, action) => {
  switch (action.type) {
    case "SET_SELLER_DATA":
      return {
        ...state,
        sellerInfoLoading: false,
        data: action.payload,
      };
    case "SET_SELLER_PRODUCTS":
      return {
        ...state,
        sellerProductLoading: false,
        sellerProductData: action.payload,
      };
    case "SET_MORE_PRODUCTS":
      return {
        ...state,
        moreProductsLoading: false,
        sellerProductData: [...state.sellerProductData, ...action.payload],
      };
    case "SET_KEEPA_CHARTS":
      return {
        ...state,
        chartUrls: [...state.chartUrls, action.payload],
      };
    case "TOGGLE_SELLER_LOADING":
      return {
        ...state,
        sellerInfoLoading: action.payload,
      };
    case "TOGGLE_PRODUCT_LOADING":
      return {
        ...state,
        sellerProductLoading: action.payload,
      };
    case "TOGGLE_MORE_PRODUCT_LOADING":
      return {
        ...state,
        moreProductsLoading: action.payload,
      };
    default:
      return state;
  }
};
