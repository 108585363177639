import { combineReducers } from "redux";

import auth from "./auth";
import stripe from "./stripe";
import storefront from "./storefront";

export default combineReducers({
  auth: auth,
  stripe: stripe,
  storefront: storefront,
});
