import React from "react";
import Rating from "@mui/material/Rating";
import { IoMdStar } from "react-icons/io";
import { LuCopy } from "react-icons/lu";
import { FaAmazon, FaGoogle } from "react-icons/fa";
import { toast } from "sonner";
import formatNumber from "./helpers/formatNumber";

const ProductInfo = ({ productData }) => {
  const overviewData = [
    {
      title: "BSR",
      value: `${
        productData?.overview?.BSR?.BSR !== -1
          ? formatNumber(productData?.overview?.BSR?.BSR)
          : "-"
      } ${
        productData?.overview?.BSR?.percentage
          ? "(" + productData?.overview?.BSR?.percentage + ")"
          : ""
      }`,
    },
    {
      title: "Max Cost",
      value: `$${productData?.overview?.max_cost}`,
    },
    {
      title: "Buy Box",
      value: `$${productData?.overview?.bb_price}`,
    },
  ];
  const handleCopy = (asin) => {
    toast.success("ASIN Successfully copied!");
    navigator.clipboard.writeText(asin);
  };
  return (
    <div className=" bg-secondary-bg rounded-xl p-3">
      <div className="flex gap-x-2 justify-between">
        <div className="relative w-fit">
          <img
            src={productData?.image}
            className="rounded-lg w-36 h-36 aspect-square"
          />
          <p className="absolute bottom-[5%] right-[5%] px-3 py-1 bg-primary text-text-dark font-semibold rounded-md">
            {" "}
            {`$${productData?.price}`}
          </p>
        </div>
        <div className="flex-1">
          <p className="font-medium text-primary w-full">
            {!productData?.basicInfo?.productName
              ? "-"
              : productData?.basicInfo?.productName?.length < 80
              ? productData?.basicInfo?.productName
              : productData?.basicInfo?.productName?.substr(0, 80) + "..."}
          </p>
          <div className="flex items-center gap-x-2">
            <Rating
              name="read-only"
              value={productData?.rating?.rating}
              precision={0.1}
              readOnly
              style={{
                fontSize: "17px",
                color: "#76F99B",
              }}
              emptyIcon={<IoMdStar className="text-[#76F99B80]" />}
            />
            <p className="text-primary">
              {formatNumber(productData?.rating?.ratings_total)}
            </p>
          </div>
          <div className="flex items-center gap-x-2 text-white">
            <p>ASIN: {productData?.asin}</p>
            <LuCopy
              className="text-primary cursor-pointer"
              onClick={() => handleCopy(productData?.asin)}
            />
          </div>
          <p className="text-white">
            Category: {productData?.basicInfo?.category}
          </p>
        </div>
        <div className="flex flex-col p-3 rounded-xl bg-[#23554380] gap-y-2 h-fit ml-auto">
          <a
            className="p-2 rounded-lg bg-primary cursor-pointer"
            target="_blank"
            href={productData?.amazonLink}
          >
            <FaAmazon className="text-text-dark text-sm" />
          </a>
          <a
            className="p-2 rounded-lg bg-primary cursor-pointer"
            target="_blank"
            href={`https://www.google.com/search?q=${productData?.basicInfo?.productName}`}
          >
            <FaGoogle className="text-text-dark text-sm" />
          </a>
        </div>
      </div>
      <div className="flex items-center gap-x-3 p-3 mt-3">
        {overviewData.map(({ title, value }) => {
          return (
            <div className="rounded-lg w-full">
              <p className="bg-[#153E27] text-white p-1 rounded-t-lg text-center">
                {title}
              </p>
              <p className="text-primary bg-[#5B8968] p-1 rounded-b-lg text-center">
                {value}
              </p>
            </div>
          );
        })}
      </div>
      <div className="flex items-center gap-x-3 px-3">
        <div className="bg-[#153E27] pt-2 pb-3 rounded-lg flex flex-col gap-y-1 text-white w-[70%] items-center justify-center">
          <p>Offers: {productData?.offers?.offersCount}</p>
          <div className="flex items-center gap-x-2">
            {productData?.offers?.amzOffers ? (
              <p className="bg-[#644513] border border-[#d5a109] rounded-lg px-2 py-1 font-medium text-sm">
                AMZ
                {productData?.offers?.amzOffers > 1 ? (
                  <span>: {productData?.offers?.amzOffers}</span>
                ) : null}
              </p>
            ) : null}
            {productData?.offers?.fbaOffers ? (
              <p className="bg-[#592810] border border-[#d5a109] rounded-lg px-2 py-1 font-medium text-sm">
                FBA
                {productData?.offers?.fbaOffers > 1 ? (
                  <span>: {productData?.offers?.fbaOffers}</span>
                ) : null}
              </p>
            ) : null}
            {productData?.offers?.fbmOffers ? (
              <p className="bg-blue-900/40 border border-[#3b82f6] rounded-lg px-2 py-1 font-medium text-sm">
                FBM
                {productData?.offers?.fbmOffers > 1 ? (
                  <span>: {productData?.offers?.fbmOffers}</span>
                ) : null}
              </p>
            ) : null}
          </div>
        </div>
        <div className="rounded-lg flex-1">
          <p className="bg-[#153E27] text-white p-2 rounded-t-lg text-center">
            Store Stock
          </p>
          <p className="text-primary bg-[#5B8968] p-1.5 rounded-b-lg text-center">
            {productData?.stock ? productData?.stock : "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
